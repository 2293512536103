import React, { useEffect } from 'react'
import Img from 'gatsby-image'

import { BookingEngineContentImages } from './styles'

const BookingEngineImages = ({ images }) => {
  const {
    BookingEngineWebMain,
    BookingEngineWebOne,
    BookingEngineWebTwo,
    BookingEngineWebThree,
    BookingEngineWebFour
  } = images
  const handleFadeInAnimation = () => {
    const bookingEngineImages = document.querySelectorAll('.booking-engine-images')

    const entryOptions = {
      threshold: 0.25
    }

    const featureObserver = new IntersectionObserver((entries, featureObserver) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return
        } else {
          entry.target.classList.add('appear')
          featureObserver.unobserve(entry.target)
        }
      })
    }, entryOptions)

    bookingEngineImages.forEach((box) => {
      featureObserver.observe(box)
    })
  }

  useEffect(() => {
    handleFadeInAnimation()
  }, [])

  return (
    <BookingEngineContentImages>
      <figure className="booking-engine-images">
        <Img fluid={BookingEngineWebMain.childImageSharp.fluid} />
      </figure>
      <figure className="booking-engine-images">
        <Img fluid={BookingEngineWebOne.childImageSharp.fluid} />
      </figure>
      <figure className="booking-engine-images">
        <Img fluid={BookingEngineWebTwo.childImageSharp.fluid} />
      </figure>
      <figure className="booking-engine-images ">
        <Img fluid={BookingEngineWebThree.childImageSharp.fluid} />
      </figure>
      <figure className="booking-engine-images ">
        <Img fluid={BookingEngineWebFour.childImageSharp.fluid} />
      </figure>
    </BookingEngineContentImages>
  )
}

export default BookingEngineImages
