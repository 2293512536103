import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { handleTrackedBookingEngineExamplesClick } from 'helper/url'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

import {
  BookingEngineSection,
  BookingEngineIntro,
  Title,
  Description,
  BookingEngineContentImages,
  BookingEngineFeatures,
  SecureBooking,
  SecureBookingWrapper,
  LivePreview,
  LinksList,
  ImageContainer,
  Note
} from './styles'

import { LanguageContext } from '../../Layout'
import Container from '../../UI/Container'
import ArrowBlue from '../../UI/Icons/ArrowBlue'
import { useBookingEngineImages } from '../../../graphql/useBookingEngineImagesHook'
import Responsive from 'components/Responsive'
import WindowSizeProvider from 'components/WindowSizeProvider'
import BookingEngineImages from '../BookingEngineContentImages'

const BookingEngineContent = ({ content }) => {
  const { i18n } = useContext(LanguageContext)
  const {
    content_title_booking_engine,
    content_subtitle_booking_engine,
    content_features_booking_engine,
    secure_title,
    secure_description
  } = content

  const {
    BookingEngineWebMain,
    BookingEngineWebOne,
    BookingEngineWebTwo,
    BookingEngineWebThree,
    BookingEngineWebFour,
    BookingEngineHero,
    BookingEngineMix,
    PingBank
  } = useBookingEngineImages()

  const handleFadeInAnimation = () => {
    const bookingEngineImages = document.querySelectorAll('.booking-engine-images')

    const entryOptions = {
      threshold: 0.25
    }

    const featureObserver = new IntersectionObserver((entries, featureObserver) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return
        } else {
          entry.target.classList.add('appear')
          featureObserver.unobserve(entry.target)
        }
      })
    }, entryOptions)

    bookingEngineImages.forEach((box) => {
      featureObserver.observe(box)
    })
  }

  useEffect(() => {
    handleFadeInAnimation()
  }, [])

  const mixpanel = useMixpanel()

  return (
    <BookingEngineSection>
      <Container width="1600">
        <BookingEngineIntro>
          <Title align="center" margin="3rem 0" width="80%">
            {content_title_booking_engine}
          </Title>
          <Description
            align="center"
            margin="5rem 0"
            width="60%"
            dangerouslySetInnerHTML={{ __html: content_subtitle_booking_engine }}
          />
          <LivePreview>
            <span>{i18n.livePreviews}</span>
            <ArrowBlue />
            <LinksList>
              <a href="https://agoura.book.rentl.io/" target="_blank" rel="noreferrer" onClick={(e) => handleTrackedBookingEngineExamplesClick(e, 'https://agoura.book.rentl.io/', '', mixpanel, 'booking_engine_preview_initiated', 'hotel')}>
                Hotel
              </a>
              <a href="https://chameleon.book.rentl.io/" target="_blank" rel="noreferrer" onClick={(e) => handleTrackedBookingEngineExamplesClick(e, 'https://chameleon.book.rentl.io/', '', mixpanel, 'booking_engine_preview_initiated', 'hostel')}>
                Hostel
              </a>
              <a href="https://horizon.book.rentl.io/" target="_blank" rel="noreferrer" onClick={(e) => handleTrackedBookingEngineExamplesClick(e, 'https://horizon.book.rentl.io/', '', mixpanel, 'booking_engine_preview_initiated', 'property_managers')}>
                {i18n.propertyManagers}
              </a>
              <a href="https://olive.book.rentl.io/" target="_blank" rel="noreferrer" onClick={(e) => handleTrackedBookingEngineExamplesClick(e, 'https://olive.book.rentl.io/', '', mixpanel, 'booking_engine_preview_initiated', 'vacation_rentals')}>
                {i18n.vacationRentals}
              </a>
            </LinksList>
          </LivePreview>
          <ImageContainer className="booking-engine-images from-bottom">
            <Img fluid={BookingEngineHero.childImageSharp.fluid} />
          </ImageContainer>
        </BookingEngineIntro>
        <BookingEngineFeatures>
          <div>
            <Title align="left" margin="0">
              {content_features_booking_engine[0].title}
            </Title>
            <Description
              align="left"
              margin="2rem 0"
              dangerouslySetInnerHTML={{ __html: content_features_booking_engine[0].description }}
            />
          </div>
          <div>
            <Title align="left" margin="0">
              {content_features_booking_engine[1].title}
            </Title>
            <Description
              align="left"
              margin="2rem 0"
              dangerouslySetInnerHTML={{ __html: content_features_booking_engine[1].description }}
            />
          </div>
        </BookingEngineFeatures>

        <WindowSizeProvider>
          <Responsive
            breakPoint={1024}
            renderDesktop={() => (
              <BookingEngineImages
                images={{
                  BookingEngineWebMain,
                  BookingEngineWebOne,
                  BookingEngineWebTwo,
                  BookingEngineWebThree,
                  BookingEngineWebFour
                }}
              />
            )}
            renderMobile={() => (
              <ImageContainer>
                <Img fluid={BookingEngineMix.childImageSharp.fluid} />
              </ImageContainer>
            )}
          />
        </WindowSizeProvider>

        <BookingEngineFeatures>
          <div>
            <Title align="left" margin="0">
              {content_features_booking_engine[2].title}
            </Title>
            <Description
              align="left"
              margin="2rem 0"
              dangerouslySetInnerHTML={{ __html: content_features_booking_engine[2].description }}
            />
          </div>
          <div>
            <Title align="left" margin="0">
              {content_features_booking_engine[3].title}
            </Title>
            <Description
              align="left"
              margin="2rem 0"
              dangerouslySetInnerHTML={{ __html: content_features_booking_engine[3].description }}
            />
          </div>
        </BookingEngineFeatures>
        <SecureBookingWrapper>
          <SecureBooking>
            <figure>
              <Img fluid={PingBank.childImageSharp.fluid} />
            </figure>
            <div>
              <Title margin="0 0 2rem auto">{secure_title}</Title>
              <Description>{secure_description}</Description>
            </div>
          </SecureBooking>
        </SecureBookingWrapper>
      </Container>
    </BookingEngineSection>
  )
}

export default BookingEngineContent

BookingEngineContent.propTypes = {
  content: PropTypes.shape({
    content_title_booking_engine: PropTypes.string,
    content_subtitle_booking_engine: PropTypes.string,
    content_features_booking_engine: PropTypes.arrayOf(PropTypes.object),
    secure_title: PropTypes.string,
    secure_description: PropTypes.string
  })
}
