import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Intro from 'components/Intro'
import CallToAction from 'components/CTA'
import BookingEngineContent from 'components/Booking-engine-screen/Booking-engine-content'
import TestimonialCarousel from 'components/UI/Testimonial-carousel'

import { useBookingEngineImages } from '../graphql/useBookingEngineImagesHook'
const MainContent = styled.main`
  background-color: #ffffff;
`

const BookingEngine = ({ data, pageContext, location }) => {
  const { BookingEngineIntro, BookingEngineSocial } = useBookingEngineImages()

  const {
    bookingEngine: {
      title,
      excerpt,
      yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc },
      acf: {
        intro_title_booking_engine,
        subtitle_booking_engine,
        content_title_booking_engine,
        content_subtitle_booking_engine,
        content_features_booking_engine,
        secure_title,
        secure_description,
        testimonials_booking_engine,
        cta_booking_engine
      }
    }
  } = data
 


  const seo = {
    title: yoast_wpseo_title || title,
    description: yoast_wpseo_metadesc || excerpt,
    image: BookingEngineSocial.childImageSharp.fluid,
    lang: pageContext.locale,
    pathname: location.pathname
  }

  return (
    <Layout locale={pageContext.locale}>
      <SEO
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
        lang={seo.lang}
        location={location}
        image={seo.image}
      />
      <Header mode="light" inverted="true" locale={pageContext.locale} color={'#014070'} location={location} />
      <Intro
        title={intro_title_booking_engine}
        subtitle={subtitle_booking_engine}
        introImg={BookingEngineIntro.childImageSharp.fluid}
        pageName="booking"
        yAxis={19}
      />
      <MainContent>
        <BookingEngineContent
          content={{
            content_title_booking_engine,
            content_subtitle_booking_engine,
            content_features_booking_engine,
            secure_title,
            secure_description
          }}
        />
        <TestimonialCarousel testimonials={testimonials_booking_engine} />
        <CallToAction data={cta_booking_engine} locale={pageContext.locale} location={location} />
      </MainContent>
      <Footer locale={pageContext.locale} location={location} />
    </Layout>
  )
}

export default BookingEngine

export const query = graphql`
  query($id: Int, $locale: String!) {
    bookingEngine: wordpressPage(wordpress_id: { eq: $id }, lang: { eq: $locale }) {
      title
      excerpt
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(quality: 85, maxWidth: 1300) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
      acf {
        intro_title_booking_engine
        subtitle_booking_engine
        content_title_booking_engine
        content_subtitle_booking_engine
        content_features_booking_engine {
          title
          description
        }
        secure_title
        secure_description
        testimonials_booking_engine {
          description
          name
          property
        }
        cta_booking_engine {
          title
          description
        }
      }
    }
  }
`
