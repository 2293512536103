import styled from 'styled-components'

export const BookingEngineContentImages = styled.div`
  display: flex;
  position: relative;
  max-width: 1200px;
  margin: 8rem auto 10rem auto;

  figure {
    &:nth-of-type(1) {
      width: 100%;
      height: 100%;
    }

    &:nth-of-type(2) {
      width: 885px;
      height: auto;
      z-index: 1;
      position: absolute;
      left: 74px;
      top: 106px;
      transition: transform 1s ease-in-out;

      &.appear {
        transform: translateX(-3%);
      }
    }
    &:nth-of-type(3) {
      width: 290px;
      height: auto;
      position: absolute;
      right: 0;
      z-index: 2;
      transform: translateX(-90%);
      transition: transform 1s ease-in-out;

      &.appear {
        transform: translateX(-70%);
      }
    }

    &:nth-of-type(4) {
      width: 600px;
      height: auto;
      z-index: 1;
      position: absolute;
      bottom: 100px;
      left: 188px;
      transition: transform 1s ease-in-out;

      &.appear {
        transform: translateX(-12%);
      }
    }

    &:nth-of-type(5) {
      width: 900px;
      height: auto;
      z-index: 5;
      bottom: -90px;
      right: 87px;
      position: absolute;
      transition: transform 1s ease-in-out;

      &.appear {
        transform: translateY(-8%);
      }
    }

    @media screen and (max-width: 425px) {
      &:nth-of-type(1),
      &:nth-of-type(3) {
        width: 190px;
      }
      &:nth-of-type(2) {
        width: 250px;
      }
    }
  }
`
