import React from 'react'

const ArrowBlue = ({ className, width, height, color }) => {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 21 16" fill="none">
      <path
        d="M20.707 8.707a1 1 0 000-1.414L14.343.929a1 1 0 10-1.414 1.414L18.586 8l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM0 9h20V7H0v2z"
        fill={color}
      />
    </svg>
  )
}

const defaultProps = {
  width: 21,
  height: 16,
  color: '#1D9ED9'
}

ArrowBlue.defaultProps = defaultProps
export default ArrowBlue
