import styled from 'styled-components'

export const BookingEngineSection = styled.section`
  padding: 15rem 0 5rem 0;
  overflow: hidden;

  @media screen and (max-width: 1180px) {
    padding: 2rem 0;
  }
`

export const BookingEngineIntro = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 7rem;
`

export const Title = styled.h4`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 2.85rem;
  font-weight: bold;
  line-height: 3.28rem;
  text-align: ${({ align }) => align};
  margin: ${({ margin }) => (margin ? margin : `0`)};
  width: ${({ width }) => width};

  @media screen and (max-width: 425px) {
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 1rem 0;
  }

  @media screen and (max-width: 1180px) {
    width: 100%;
  }
`
export const Description = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.14rem;
  line-height: inherit;
  text-align: ${({ align }) => align};
  margin: ${({ margin }) => (margin ? margin : `0`)};
  ${({ width }) => `width: ${width || `100%`}`};

  @media screen and (max-width: 1024px) {
    margin-bottom: 0;
  }

  @media screen and (max-width: 425px) {
    width: 100%;
    margin-bottom: 3rem;
  }
`

export const LivePreview = styled.div`
  display: flex;
  align-items: center;
  max-width: 1000px;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin: 8rem auto 12rem auto;
  padding: 4rem;

  span {
    display: inline-block;
    width: 100%;
    flex-shrink: 3;
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-family: 'Barlow';
    font-size: 1.71rem;
    font-weight: bold;
    line-height: 2.57rem;
    margin-right: 2.14rem;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    text-align: center;
    margin-bottom: 7rem;
    margin-top: 2rem;
    padding: 2rem;

    svg {
      transform: rotate(90deg);
    }

    span {
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }
`

export const LinksList = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  a {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-size: 1.28rem;
    font-weight: bold;
    line-height: 1.5rem;
    position: relative;
    margin: 0 2rem;

    &:hover {
      ::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 6px;
        background-color: ${({ theme }) => theme.colors.vividBlue};
        opacity: 0.3;
        bottom: 1px;
        left: 0;
        opacity: 0.2;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;

    a {
      margin-bottom: 1.5rem;
    }
  }
`

export const BookingEngineFeatures = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;

  div {
    width: 530px;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    margin: 4rem 0;
  }
`
export const SecureBookingWrapper = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;

  &::after {
    content: '';
    width: 600px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -11%;
    background: radial-gradient(62.75% 43.37% at 60.61% 63.99%, #a9cf3a 0%, rgba(255, 255, 255, 0) 100%),
      radial-gradient(47.63% 30.01% at 56.82% 58.96%, rgba(29, 158, 217, 0.47) 0%, rgba(29, 158, 217, 0) 100%);
    z-index: 0;
    opacity: 0.6;
    transform: translateY(-28%);

    @media screen and (max-width: 425px) {
      width: 100%;
      top: -26px;
      left: -27px;
    }
  }
`
export const SecureBooking = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-radius: 10px;
  position: relative;
  padding: 3rem;
  margin: 8rem 0;
  width: 100%;
  z-index: 1;

  figure {
    width: 500px;
    height: 100%;
    margin: 1rem;
  }

  > div {
    max-width: 550px;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    margin: 5rem 0;
    padding: 2rem;

    figure {
      margin: 0;
      width: 300px;
    }
  }
`
export const ImageContainer = styled.figure`
  width: 100%;

  &.from-bottom {
    transition: all 0.8s ease-in-out;
    transform: translateY(15%);
    opacity: 0;

    &.appear {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @media screen and (max-width: 1024px) {
    margin-top: -4rem;
  }
`

export const Note = styled.div`
  display: flex;
  max-width: 480px;
  justify-content: center;
  background: ${({ theme }) => theme.colors.vividBlue};
  box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.2);
  border-radius: 10px;
  color: #fff;
  font-weight: 500;
  font-size: 1.42rem;
  line-height: 30px;
  padding: 30px;
  margin-top: 4.5rem;

  @media screen and (max-width: 1024px) {
    padding: 20px 10px;
    text-align: center;
  }
`
