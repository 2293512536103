import { useStaticQuery, graphql } from 'gatsby'

export const useBookingEngineImages = () => {
  const images = useStaticQuery(graphql`
    fragment BookingEngineFluidImage on File {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      BookingEngineIntro: file(relativePath: { eq: "images/booking-engine-illustration.png" }) {
        ...BookingEngineFluidImage
      }
      BookingEngineWebMain: file(relativePath: { eq: "images/bookingengine-web-main.jpg" }) {
        ...BookingEngineFluidImage
      }
      BookingEngineWebOne: file(relativePath: { eq: "images/bookingengine-web-1.png" }) {
        ...BookingEngineFluidImage
      }
      BookingEngineWebTwo: file(relativePath: { eq: "images/bookingengine-web-2.png" }) {
        ...BookingEngineFluidImage
      }
      BookingEngineWebThree: file(relativePath: { eq: "images/bookingengine-web-3.png" }) {
        ...BookingEngineFluidImage
      }
      BookingEngineWebFour: file(relativePath: { eq: "images/bookingengine-web-4.png" }) {
        ...BookingEngineFluidImage
      }
      PingBank: file(relativePath: { eq: "images/piggy-bank-illustration.jpg" }) {
        ...BookingEngineFluidImage
      }
      BookingEngineHero: file(relativePath: { eq: "images/booking-engine-illustration-hero.jpg" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      BookingEngineMix: file(relativePath: { eq: "images/booking-engine-mix-images.jpg" }) {
        ...BookingEngineFluidImage
      }
      BookingEngineSocial: file(relativePath: { eq: "images/bookingengine-social.jpg" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return images
}
